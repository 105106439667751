.emoji40, .emoji41, .emoji43, .emoji42, .emoji1, 
.emoji10, .emoji11, .emoji12, .emoji13, .emoji14, 
.emoji15, .emoji16, .emoji17, .emoji18, .emoji19, 
.emoji2, .emoji20, .emoji21, .emoji22, .emoji23, 
.emoji24, .emoji25, .emoji26, .emoji27, .emoji28, 
.emoji29, .emoji3, .emoji30, .emoji31, .emoji32, 
.emoji33, .emoji34, .emoji35, .emoji36, .emoji37, 
.emoji38, .emoji39, .emoji4, .emoji5, .emoji6, 
.emoji7, .emoji8, .emoji9
{ max-width: 100%; background-size: 100%; background-image: url('../images/tool-icons/spritesheet.png'); }
 
.emoji40 { background-position: 0 0%; background-size: 100%; }
.emoji41 { background-position: 0 2.380952%; background-size: 100%; }
.emoji43 { background-position: 0 4.761905%; background-size: 205.548854%; }
.emoji42 { background-position: 0 7.142857%; background-size: 232.786885%; }
.emoji1 { background-position: 0 9.52381%; background-size: 1136%; }
.emoji10 { background-position: 0 11.904762%; background-size: 1136%; }
.emoji11 { background-position: 0 14.285714%; background-size: 1136%; }
.emoji12 { background-position: 0 16.666667%; background-size: 1136%; }
.emoji13 { background-position: 0 19.047619%; background-size: 1136%; }
.emoji14 { background-position: 0 21.428571%; background-size: 1136%; }
.emoji15 { background-position: 0 23.809524%; background-size: 1136%; }
.emoji16 { background-position: 0 26.190476%; background-size: 1136%; }
.emoji17 { background-position: 0 28.571429%; background-size: 1136%; }
.emoji18 { background-position: 0 30.952381%; background-size: 1136%; }
.emoji19 { background-position: 0 33.333333%; background-size: 1136%; }
.emoji2 { background-position: 0 35.714286%; background-size: 1136%; }
.emoji20 { background-position: 0 38.095238%; background-size: 1136%; }
.emoji21 { background-position: 0 40.47619%; background-size: 1136%; }
.emoji22 { background-position: 0 42.857143%; background-size: 1136%; }
.emoji23 { background-position: 0 45.238095%; background-size: 1136%; }
.emoji24 { background-position: 0 47.619048%; background-size: 1136%; }
.emoji25 { background-position: 0 50%; background-size: 1136%; }
.emoji26 { background-position: 0 52.380952%; background-size: 1136%; }
.emoji27 { background-position: 0 54.761905%; background-size: 1136%; }
.emoji28 { background-position: 0 57.142857%; background-size: 1136%; }
.emoji29 { background-position: 0 59.52381%; background-size: 1136%; }
.emoji3 { background-position: 0 61.904762%; background-size: 1136%; }
.emoji30 { background-position: 0 64.285714%; background-size: 1136%; }
.emoji31 { background-position: 0 66.666667%; background-size: 1136%; }
.emoji32 { background-position: 0 69.047619%; background-size: 1136%; }
.emoji33 { background-position: 0 71.428571%; background-size: 1136%; }
.emoji34 { background-position: 0 73.809524%; background-size: 1136%; }
.emoji35 { background-position: 0 76.190476%; background-size: 1136%; }
.emoji36 { background-position: 0 78.571429%; background-size: 1136%; }
.emoji37 { background-position: 0 80.952381%; background-size: 1136%; }
.emoji38 { background-position: 0 83.333333%; background-size: 1136%; }
.emoji39 { background-position: 0 85.714286%; background-size: 1136%; }
.emoji4 { background-position: 0 88.095238%; background-size: 1136%; }
.emoji5 { background-position: 0 90.47619%; background-size: 1136%; }
.emoji6 { background-position: 0 92.857143%; background-size: 1136%; }
.emoji7 { background-position: 0 95.238095%; background-size: 1136%; }
.emoji8 { background-position: 0 97.619048%; background-size: 1136%; }
.emoji9 { background-position: 0 100%; background-size: 1136%; }