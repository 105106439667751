//$base-path: '../';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import "hamburgers/hamburgers.scss";
@import "_config.scss";

$main: #203552;
$bg-light: #e5f7fc;
$bg: #cfedf5;
$bg-superlight: #f2fdff;
$facebook: #3969cf;
$twitter: #00a6e7;


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

html{
  height: 100%;
  box-sizing: border-box;
  margin:0;
  padding:0;
}

body {
  overflow-x:hidden; 
  margin:0;
  padding:0;
  color:$main;
  position: relative;
  margin: 0;
  padding-bottom: 0;
  min-height: 100%;
  font-family: "Ubuntu", sans-serif;
}

.modal{

  .btn{
    background-color:transparent !important;
    border-radius:6px;
    border:3px solid #fff;
    span{
      color: #fff !important;
    }
  }

  .btn.logout{
    span{
      color: #fff !important;
      text-decoration: underline;
    }
  }
}

.modal-backdrop.in{
  opacity: 0.5 !important;
}

.cropper-line, .cropper-point {
  display: none !important;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.header {
  height:90px;
  background: #ffffff;
  position:relative;

  .border-bottom-bg{
    position:absolute;
    bottom:0px;
    height: 100%;
    width: 100%;
    background-position:center;
    background-size:100% 100%;
    background-repeat:no-repeat;

    @media screen and (max-width : 991px) {
      background-size:300% 100%;
    }
    @media screen and (max-width : 767px) {
      display:none;
    }
  }

  @media screen and (max-width : 991px) {
    height: 80px;
  }
  @media screen and (max-width : 767px) {
    height:90px;
  }

  .mob-btn {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 101;
    display: none;

    @media screen and (max-width : 767px) {
      display:none;
    }

    button {
      outline: 0 !important;
      background: #212446;

      @media screen and (max-width : 767px) {
        background: #e08536;
      }
    }
  }

  .mob-menu {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .2s ease;
    transition-delay: .2s;
    transform:translate(0%,0);
    z-index: -1;

    .links {
      display: block;
      position: absolute;
      min-width: 100%;
      top: 50%;
      width: 100%;
      left: 0;
      text-align: center;
      padding-bottom: 0px;
      transform: translate(0, -50%);

      .link {
        transform:scale(1,1);
        transition:transform .2s ease;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 13pt;
        font-weight: bold;
        float: none;
        margin-top:20px;
        color: #104f99;
      }

      .link:hover, .link.active {
        transform:scale(1.1,1.1);
        text-decoration: underline;        
      }

      .link.active {
      }

      a {
        text-decoration: none;
      }
    }

    .links.signs {
      padding-bottom: 0px;
      transform: translate(0, 0);
      position: absolute;
      top: initial;
      bottom: 40px;
      margin-top: 30px;

      .sign, .signed {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        margin-top: 4px;
      }
    }
  }

  .mob-menu.in {
    opacity:1;
    z-index: 100;
    transform:translate(0,0);
  }

  .links {
    z-index: 2;
    position: absolute;
    top: 35px;
    width: 50%;
    min-width: 385px;

    @media screen and (max-width : 767px) {
      display:none;
    }

    .link {
      transition: color .3s ease;
      font-weight: bold;
      font-size: 11pt;
      color:#104f99;
      opacity:1;
      padding-left: 50px;
      padding-right: 20px;
      text-align: center;
      float: right;
      cursor: pointer;

      @media screen and (max-width : 991px) {
        font-size: 8pt;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .link.active {
      opacity:1;
    }

    .sign-container{
      float: left;
      margin-top: -26px;
    }

    .link.sign, .link.signed {
      border:none;
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      text-transform: capitalize;
      color: #fff;
      text-overflow: ellipsis;
      background-color: #212446;
      border-radius: 4px;
      font-weight:bold;
      font-size:10pt;
      padding-top: 6px;
      padding-bottom: 6px;
      margin-left: 2px;
      margin-right: 2px;
      margin-top: -4px;
      float: none;
    }

    .link.sign{
      float: none !important;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4px;
      margin-top: 4px;
      width: 120px;
      @media screen and (max-width : 991px) {
        width: 100px;
      }
    }

    .link.signed{ 
      width: 120px;
      @media screen and (max-width : 767px) {
        width: 100%;
      }
    }
  }

  .links.left {
    left: -90px;
    @media screen and (max-width : 991px) {
      left: -90px;
    }
  }

  .links.right {
    right: -90px;
    @media screen and (max-width : 991px) {
      right: -90px;
    }

    .link {
      padding-left: 20px;
      padding-right: 50px;
      text-align: center;
      float: left;

      @media screen and (max-width : 991px) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .center-area {
    position:absolute;
    left:50%;
    display:table;
    top:0;
    z-index: 2;
    text-align: center;
    transform:translate(-50%,0);

    .over {
      position: relative;
      z-index: 10;
    }

    .main-text {
      color: #fff;
      margin-top: 80px;
      font-size: 19pt;

      @media screen and (max-width : 991px) {
        font-size: 16pt;
      }
      @media screen and (max-width : 767px) {
        font-size: 16pt;
        margin-top: 47px;
      }
    }

    .sub-text {
      padding-top: 0px;
      font-size: 12pt;
      margin-top: -4px;
      font-weight: 500;
      color: #000;
      
      strong {
        font-size: 14pt;
      }
      
      @media screen and (max-width : 767px) {
        font-size: 11pt;
        strong {
          font-size: 12pt;
        }
      }
    }

    .triangle {
      position:absolute;
      top:13px;
      left:50%;
      z-index:1;
      width:220px;
      height:65px;
      margin-left:-110px;
      background-position:center;
      background-size:contain;
      background-repeat: no-repeat;
      background-image:url(../images/fila-logo.png);

      @media screen and (max-width : 991px) {
        width:200px;
        top: 10px;
        height:60px;
        margin-left:-100px;
      }
      
      @media screen and (max-width : 767px) {
        width:200px;
        top: 5px;
        height:80px;
        margin-left:-100px;
      }
    }
  }
}

.page-wrapper {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

a {
  color:$main !important;
  text-decoration: none !important;
}

.common {
  position:relative;
}

section.dark {
  color:#fff;
  .section-background {
    background-color:#264375;
  }
}

section {
  outline:none;
  color:#264375;
  position:relative;
  .section-background {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-position:center;
    background-size:cover;
  }

  .section-container {
    position:relative;
    margin-left:auto;
    margin-right:auto;
    width:100%;
    padding-top:10px;
    padding-bottom:10px;
    padding:10px;
    max-width:650px;
    text-align:center;

    .button {
      margin-top:50px;
      margin-bottom:50px;
      border-radius:50px;
      border:none;
      background:#264375;
      color:#fff;
      font-size:14pt;
      padding:12px;
      padding-left:15px;
      padding-right:15px;
      font-weight:bold;
    }

    .title {
      padding-top:10px;
      padding-bottom:10px;
      height:80px;
      width:90%;
      position:relative;
      left:5%;
      background-repeat:no-repeat;
      background-size:contain;
      background-position:center;
    }
    .title-alt {
      position:absolute;
      width:100%;
      left:-2000px;
      opacity:0;
    }
    .text {
      text-align:center;
      margin-top:10px;
      padding:10px;
      line-height:120%;
      @media screen and (max-width : 600px) {
        font-size: 11pt;
      }
    }

    h2 {
      font-weight:600;
      @media screen and (max-width : 400px) {
        font-size: 14pt;
      }
    }
  }
}

.main-section {
  .main-section-container {
    width: 100%;
    height: 0px;
    padding-bottom:40%;
    @media screen and (max-width :1300px) {
      padding-bottom:46%;
    }
    @media screen and (max-width :1150px) {
      padding-bottom:55%;
    }

    @media screen and (max-width :1050px) {
      padding-bottom:70%;
    }
    @media screen and (max-width :800px) {
      padding-bottom:100%;
    }

    @media screen and (max-width :600px) {
      padding-bottom:0%;
      height: 700px;
    }

    @media screen and (max-width :400px) {
      padding-bottom:0%;
      height: 760px;
    }

    .side-container {
      overflow: hidden;
      position:absolute;
      width: 100%;
      height: 100%;
    }
    .side {

      width: 50%;
      float: left;
    }

    .left-side {
      height: 85%;
      .left-background {
        height: 100%;
        width: 110%;
        left: -10%;
        position:relative;
        top: 0;
        background-position:100% 50%;
        background-size: contain;
        background-repeat:no-repeat;
        background-image:url(../images/left-side.png);
      }
    }

    .right-side {
      height: 85%;
      position:relative;
      padding-top:30px;

      .text-download {
        width: 100%;
        max-width: 340px;
        padding-right:10px;
        font-size: 10pt;
        line-height: 30px;
        img {
          height: 30px;
          @media screen and (max-width :400px) {
            height: 20px;
          }
        }

        @media screen and (max-width :400px) {
          font-size: 9pt;
          line-height:20px;
        }

      }

      .loghi {
        position: relative;
        left: -30px;
        right: 0;
        bottom:0;
        height:70%;
        max-height: 500px;
        background-position:0% 50%;
        background-size: contain;
        background-repeat:no-repeat;
        background-image:url(../images/loghi-vertical.png);
      }

      .btn-container {
        position:relative;
        display: inline-block;
        margin-bottom: 20px;
        .btn-back{
          z-index: 1;
          left: 5px;
          right: -5px;
          top: 5px;
          bottom:-5px;
          background-color:#ccc;
          position:absolute;
        }
      }

      .download-button {
        background-color:#f08113;
        border:none;
        box-shadow:none;
        color: #fff;
        font-weight: bold;
        padding:3px;
        padding-right:15px;
        padding-left:15px;
        position:relative;
        font-size: 12pt;
        z-index: 2;
      }

      .download-button:hover {
        opacity: 0.85;
      } 
    }

    .bottom-image {
      float: left;
      margin-top: -15px;
      position:relative;
      z-index: 10;
      width: 100%;
      height: 15%;
      margin-left: -25px;
      background-position:50% 50%;
      background-size: auto 90%;
      background-repeat:no-repeat;
      background-image:url(../images/feel-creative-logo.png);
    }
  }
}

#explaination, #footer {
  .section-background {
    background-color:#f08113;
  }

  .section-container {
    max-width:560px;
  }
}

#footer {
  
  .section-container {

    .text {
      text-align:center;
      margin-top:10px;
      padding:10px;
      line-height:150%;
      font-size: 11pt;
      @media screen and (max-width : 600px) {
        font-size: 9pt;
      }

      a {
        color: #fff !important;
      }
    }
  }
}

#prezzi {
  .section-background {
  }

  .section-container {
    max-width:450px;

    .title {
      background-image:url(../images/prezzi-title.png);
    }
  }
}

#orari {
  .section-background {
    background-image:url(../images/section-blu.jpg);  
  }

  .section-container {
    max-width:400px;

    .title {
      background-image:url(../images/orari-title.png);
    }
  }
}


#ritiro {
  .section-background {
  }

  .section-container {
    max-width:460px;

    .title {
      background-image:url(../images/ritiro-title.png);
    }
  }
}

.footerinho {
  border-bottom:20px solid #264375;
  margin-top:40px;

  text-align:center;
  .carrefour-logo {
    margin-left:auto;
    margin-right:auto;
    position:relative;
    width:250px;
    height:50px;
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
    margin-bottom:20px;
  }
}

section.tool {

  .tool-container {
    text-align: center;
    color: #000;
    max-width: 900px !important;
    width: 100% !important;
    margin-top: 20px;
    box-shadow: 0px 10px 40px rgba(100,100,100,0.1);
    background-color: rgba(255,255,255,0.8);
    padding: 40px;
    padding-top: 40px;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 40px;

    @media screen and (max-width : 767px) {
      min-height:550px;
      padding-top: 40px;
      box-shadow: none;
      padding-right:0px !important;
      padding-left: 0px !important;
      
      .row {
        margin: 0;
      }
    }

    .bg {
      position: absolute;
      background-size:contain;
      background-repeat: no-repeat;
      z-index: 0;
    }

    .cta {
      z-index: 1;
      position: relative;
    }

    .bg.top {
      height: 280px;
      width: 30%;
      top: 0px;
      left: -10px;
      background-position: 0% 0;
      background-image: url(../images/personaggio1.png);

      @media screen and (max-width : 991px) {
        height:200px;
        bottom: 30px;
      }

      @media screen and (max-width : 767px) {
        top:initial;
        width:100%;
        bottom:20px;
        left:0;
        background-position:center;
      }
    }

    .bg.bottom {
      height: 260px;
      width: 30%;
      top: 30%;
      right: -20px;
      background-position: 0% 0;
      background-image: url(../images/personaggio2.png);
      @media screen and (max-width : 991px) {
        height:180px;
        top: 35%;
        right: -35px;
        background-position: 50% 0;
      }
    }

    .title {
      position: relative;
      margin-right: auto;
      margin-left: auto;
      max-width: 400px;

      @media screen and (max-width : 767px) {
        max-width: 250px;
      }

      h4 {
        font-weight: normal;
        font-size: 12pt;
      }
    }

    .flag {
      position: relative;
      color: $main;
      margin-right: auto;
      margin-left: auto;
      max-width: 55%;

      @media screen and (max-width : 767px) {
        margin-bottom:20px;
        margin-top: 20px;
      }


      input {
        
        @media screen and (min-width : 992px) {
          margin-left: -27px;
          margin-top: 10px;
        }
      }

      @media screen and (max-width : 991px) {
        max-width:320px;
      }

      a {
        text-decoration: underline;
      }

      label {
        font-size: 8pt;
      }
    }

    .t-containers {
      
      @media screen and (max-width : 767px) {
        position:fixed;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10000;
        transform:translate(0px,100%);
      }

      .tool-header{
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1000;
        background-color: #dcf0f3;
        height: 40px;
        transition:transform 0.4s ease;
        transform:translate(0px,-40px);

        .left {
          position: absolute;
          left: 10px;
          width: 25px;

          .button-close {
            padding: 0;
            padding-left: 10px;
            padding-right: 10px;
            font-weight: bold;
            margin-top: -5px;
            border: none;
            background-color: transparent;
            color: #74c6cc;
            font-size: 22pt;
            transform:scaleX(1.2);
          }
        }

        .right {
          position: absolute;
          right: 10px;
          text-align: right;

          .separator{
            float: left;
            height: 26px;
            background-color: #74c6cc;
            width: 2px;
            border-radius: 2px;
            margin-top: 7px;
          }

          .button {
            float: left;
            padding: 0;
            font-weight: 500;
            margin-top: -3px;
            color: #74c6cc;
            font-size: 13pt;
            transform:scaleX(1.2);
          }

          .button-face {
            width: 40px;
            height: 40px;
            margin-right: 5px;
            margin-left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 0px;
            background-size: 30px 30px;
            border: none;
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../images/smile.png);
          }

          .button-save {
            border: none;
            background-color: transparent;
            text-align: left;
            width: 70px;
          }
        }
      }
    }

    .t-container {
      float: left;
      height: 460px;

      @media screen and (max-width : 767px) {
        display:block;
        height: 100%;
        width: 100%;
        position: absolute;
        padding: 0 !important;

        .box {

          width: 100% !important;
        }
      }

      .box {
        height: 100%;
        background-color: #fff;
      }
    }

    .preview {
      padding: 10px;
      margin-left: 50px;

      @media screen and (max-width : 991px) {
        margin-left:20px;
      }

      @media screen and (max-width : 767px) {
        margin-left:0px;
        z-index:100;
        transition:transform 0.4s ease;
        transform:translate(0px,100%);
      }
      
      .box {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/tool-icons/carica-qui.png);
        width: 250px;
        position: relative;
        overflow: hidden;

        @media screen and (max-width : 767px) {
          background-color:#fff;
          margin-bottom: 20px;
          overflow: auto;

          .tool-img-wrap {
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            right: 0;
            top: 40px;
            bottom: 0px;
            margin-right: auto;
            margin-left: auto;
          }
        }

        .left-handle {
          position: absolute;
          height: 100%;
          width: 30px;
          left: 0;
          top: 0;
          z-index: 10000;
          display:none;

          @media screen and (max-width : 767px) {
            display:block;
          }
      
        }
        
        .right-handle {
          position: absolute;
          height: 100%;
          width: 30px;
          right: 0;
          top: 0;
          z-index: 10000;
          display:none;

          @media screen and (max-width : 767px) {
            display:block;
          }
        }

        canvas {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 8;
          height: 100%;
        }

        #image-canvas {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .resize-drag {
            color: transparent;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -50px;
            width: 100px;
            height: 100px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 5px;
            border: 4px solid transparent;
            z-index: 10000;
            cursor: move;

            .image {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }

            .border {
              position: absolute;
              top: 0;
              opacity: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: 100%;
              width: 100%;
              border: 4px solid $bg;    
              border-radius: 2px;
            }
          }

          .resize-drag.on {
            
            .border {
              opacity: 1;
            }

            .handle {
              display: block;
            }
          }

          .resize-drag:hover {
            border: 4px solid transparent;
          } 
        }

        .handle {
          display: none;
          cursor: pointer;
          position: absolute;
          height: 30px;
          width: 30px;
          border-radius: 50px;
          background: $main;
        }

        .rotate-handle {
          right: -15px;
          bottom: -15px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 60% 60%;
          background-image: url(../images/rotate-32.png);
        }

        .resize-handle {
          left: -15px;
          top: -15px;
          
          background-position: center;
          background-repeat: no-repeat;
          background-size: 60% 60%;
          background-image: url(../images/resize-32.png);
        }

        .foreground {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 9;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          pointer-events: none;
          background-image: url(../images/tool-icons/foreground.png);
        }

        .trash {
          position: absolute;
          left: 50%;
          bottom: 10px;
          width: 80px;
          border:2px dashed #ccc;
          height: 50px;
          background-color: #fff;
          margin-left: -40px;
          border-radius: 10px;
          z-index: 15;
          opacity: 0;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          pointer-events: none;
          background-image: url(../images/trash.png);

          @media screen and (max-width : 767px) {
            bottom:initial;
            top: 10px;
            width: 50px;
            height: 40px;
            margin-left: -25px;
          }
        }

        .trash.drop-active {
          background-color: #e94d43;
        }

        .trash.on {
          opacity: 0.75;
        }
      }
    }

    .t-container.save {
      @media screen and (max-width : 767px) {
        display:none;
      }
    }

    .save {
      padding: 10px;
      position: relative;
      width: 240px;

      @media screen and (max-width : 991px) {
        width:120px;
      }

      .cta {
        position: relative;
        top: 50%;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: -20px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .emojis {
      padding: 10px;
      margin-left: 10px;

      @media screen and (max-width : 767px) {
        margin-left:0px;
        z-index:101;
        top: 40px;
        transition:transform 0.4s ease;
        transform:translate(0px,100%);
      }

      .box {
        position: relative;
        box-shadow: 0px 10px 20px rgba(100,100,100,0.05);
        width: 300px;
        border-radius: 10px;
        border: 6px solid #fff;
        background-color: rgb(245,252,254);

        @media screen and (max-width : 767px) {
          background-color: rgba(245,252,254,0.85);
          border: none;
        }

        .intro {
          position: relative;
          background-color: rgb(245,252,254);
          padding-top: 5px;
          font-weight: normal;
          margin-top: 0;
          font-size: 9pt;
          z-index: 1;
          @media screen and (max-width : 767px) {
            font-weight:500;
          }
        }

        .emoji-container {
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px;
          padding-top: 40px;
          height: 100%;
          width: 100%;
          overflow: auto;
          -webkit-overflow-scrolling: touch;

          .wrapper {
            border: 1px solid $bg;
            width: 100%;
            display: table;

            @media screen and (max-width : 767px) {
              border:none;
            }

            .item {
              width: 20%;
              padding: 5px;
              padding-top: 5px;
              background-size: 80% auto;
              background-position: center;
              background-repeat: no-repeat;
              float: left;
              cursor: pointer;
              position: relative;

              img {
                width: 80%;
                margin-left: 5%;
              }

              .sprite {
                width: 100%;
                padding-bottom: 100%;
                display: block;
              }
            }

            .item.big {
              width: 100%;

              .sprite {
                padding-bottom: 8%;
              }
            }

            .item.bigl {
              width: 100%;
              
              .sprite {
                padding-bottom: 20%;
              }
            }

            .item:hover {
              background-color: $bg;
            }
          }
        }
      }
    }

    .onimage {
      transform:translate(0px,0px);
      .tool-header {
        opacity: 1;
        transform:translate(0px,0px);
      }

      .preview {
        transform:translate(0px,0px);

        .box {
          
        }
      }
    }

    .onemojis {

      .emojis {
        transform:translate(0px,0px);

        .box {
          
        }
      }
    }
  }
}

.ca-code {

  .inside {
    max-width: 84%;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width : 767px) {
      max-width: 100%;
    }

    .code {
      width: 100%;
      padding: 5px;
      background-color: #fff;
      color: $main;
      font-weight: 500;
      font-size: 20pt;
      text-transform: uppercase;
    }

    .conditions {
      margin-top: 15px;
      width: 100%;
      text-align: center;
      background-color: #fff;
      height: 180px;
      overflow: auto;
      color: #000;
      margin-bottom: 20px;
      font-weight: 500;
      padding-top: 10px;

      .text {
        font-weight: normal;
        font-size: 9pt;
        padding: 10px;
      }
    }
  }
}

.thankyou {

  .container.tool-container{
    padding-top: 150px;
    padding-bottom: 150px;
    @media screen and (max-width : 767px) {
      padding-top: 50px;
      padding-bottom: 200px;
    }
  }

  .content {
    position: relative;

    h3 {
      @media screen and (max-width : 767px) {
        font-size:12pt;
      }
    }

    h4 {
      margin-top: 30px;
      font-weight: normal;
      max-width: 250px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  
  .back{
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/thankyou.png);
    @media screen and (max-width : 767px) {
      background-size: 900px auto;
      background-position: 40px 110px;
    }
  }
}

body.gallery {
  background-color: $bg-superlight;
  
  .page-wrapper {
    padding-bottom: 100px;
  }

  .load-more {
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    top: -100px;
  }

  section.third {
    background-color: $bg-superlight;

    .pic-header {
      position: relative;
      height: 140px;

      .title {
        padding-top: 60px;
      }

      .bg {
        position: absolute;
        height: 100%;
        top: 0;
        width: 200px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @media screen and (max-width : 767px) {
          display:none;
        }
      }

      .bg.left {
        left: 0;
        background-image: url(../images/personaggio1.png);
      }

      .bg.right {
        right: 0;
        background-image: url(../images/personaggio2.png);
      }
    }

    .loading {
      background: #fff;
      width: 180px;
      border-radius: 40px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;

      img {
        width: 100%;
      }
    }

    .pics {

      .pic {
        background-color: $bg-light;
      }
    }
  }

  .pic-modal {

    .modal-content {
      background-color: #e1f1f5;
      margin-top: 120px;
      border-radius: 10px;
      min-height: 200px;

      .arrow {
        position: absolute;
        top: 50%;
        margin-top: -25px;
        height: 50px;
        width: 50px;
        background-color: #fff;
        border: none;
        color: #aecad1;
        font-size: 22pt;
        border-radius: 50px;
        outline: none;
        span {
          display: block;
          margin-top: -10px;
          transform:scaleY(1.7);
        }
      }

      .arrow.left {
        left: 10%;
      }

      .arrow.right {
        right: 10%;
      }

      .nome {
        text-align: center;
      }

      .pic {
        color: #000;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .close-btn {
        position: absolute;
        right: 10px;
        top: 0px;
        background-color: transparent;
        border: none;
        font-size: 30pt;
        color: #aecad1;
        transform:scaleX(1.2);
      }
    }

  }
}

.priv-policy{

  .container.tool-container{
    max-height: 500px;
    overflow: auto;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.fade{
  transition: opacity .2s linear;
}

.modal.fade {

  .modal-dialog {
    transform: scale(0.95);
    opacity: 0;
    transition: all 0.3s;
  }
}

.modal.fade.in {

  .modal-dialog {
    transform: scale(1);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

#loading{
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);

  img {
    opacity: 0.6;
    background-color: transparent;
    border-radius: 40px;
    width: 200px;
    margin-left: -100px;
    margin-top: -60px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

input.error {
  border: 2px solid red !important;
  color: #000 !important;
  outline: none;
}
label.error {
  padding: 2px;
  color: #ff5555 !important;
  outline: none;
}
.error-messages {
  color: red;
}
.e-msg.error{
  color: red;
  background: transparent;
}

.cb{
  position:fixed;
  background:#264375;
  bottom:0;
  left:0;
  right:0;
  widht:100%;
  padding:40px;
  color:#fff;
  opacity:1;
  z-index:10000;
  text-align:center;

  .text {
    font-size:10pt;
    @media screen and (max-width : 767px) {
      font-size:9pt;
    }
  }

  .close-btn {
    position:absolute;
    top:10px;
    right:20px;
    font-size:18pt;
    font-weight:bold;
    cursor:pointer;
  }

  a {
    color: #fff !important;
    font-weight:bold;
  }
}

body.in-myc4 {

  .main-bg {
    height: 100% !important;
  }

  .sign-modal {
    .modal-dialog {
      margin-top: 110px;
      @media screen and (max-width : 767px) {
        margin-top:110px;
      }
    }
  }

  .full-game {
    .time-container, .button-close {
      top:30px !important;
    }

    .play-container {
      @media only screen and (min-width : 375px) and (min-height : 730px) { 
        bottom: 100px !important;
      }
    }
    .stars-container {
      @media only screen and (min-width : 375px) and (min-height : 730px) { 
        bottom: 100px !important;
      }
    }
  }

  .scopri-di-piu {
    display:none;
  }

  .tizio-cont {
    display:none;
    bottom:0;
    .tizio {
      top:0;
      display:none;
    }
  }

  .rays {
    display:none;
  }

  .footer {
    display:none;
  }
  .in-app-footer {
    display:table;
    width:100%;
    margin-top:-40px;
    .footer {
      position:relative;
      display:block;
      height:260px;
      bottom:0px;
      top:0;
      .link {
        display:none;
      }
      .container {
        padding-top:30px;
        height:240px;
      }
    }
  }

  .page-wrapper {
    position:fixed;
    padding-top:100px;
    top: 0;
    right:0;
    bottom:0;
    left: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

body.in-myc4.game-wrap {
  .page-wrapper {
    padding-top:0 !important;
  }
}

#iframe {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
}